import React from 'react';
import PropTypes from 'prop-types';

import Button from '../UI/Button';
import classes from './MainSearchForm.module.css';

const MainSearchForm = (props) => {
  return (
    <div className={classes['form-control']}>
      <form onSubmit={props.onFormSubmission}>
        <input
          type="text"
          id="search"
          value={props.searchCategoryString}
          placeholder="Search"
          onChange={props.onChangeCategory}
        />
        <div>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </div>
  );
};

MainSearchForm.propTypes = {
  onChangeCategory: PropTypes.func,
  onFormSubmission: PropTypes.func,
  searchCategoryString: PropTypes.string
};

export default MainSearchForm;
