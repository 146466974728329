import { Link, useLocation } from 'react-router-dom';

import logo from '../../ske9_logo.png';
import Button from '../UI/Button';
import classes from './MainNavigation.module.css';

const MainNavigation = () => {
  // to get active page
  const location = useLocation();
  const { pathname } = location;

  return (
    <header className={classes.header}>
      <div className={classes.logos}>
        <img src={logo} className={classes['client-logo']} alt="logo" />
      </div>
      <nav>
        <ul>
          <li
            className={
              pathname === '/categories' ? classes.active : classes.inactive
            }>
            <Link to="/categories">Categories</Link>
          </li>
          <li
            className={
              pathname === '/favourites' ? classes.active : classes.inactive
            }>
            <Link to="/favourites">Favourites</Link>
          </li>
          <li
            className={
              pathname === '/exact' ? classes.active : classes.inactive
            }>
            <Link to="/exactsearch">Exact Search</Link>
          </li>
          <li
            className={
              pathname === '/contact' ? classes.active : classes.inactive
            }>
            <Link to="/Contact">Contact</Link>
          </li>
        </ul>
      </nav>
      <div className={classes['wallet-button']}>
        <Button>Connect</Button>
      </div>
    </header>
  );
};

export default MainNavigation;
