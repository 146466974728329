import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './Main.module.css';
import MainSearchForm from './MainSearchForm';

const MainSearch = (props) => {
  const [search, setSearch] = useState('');

  const categoryInputChangeHandler = (event) => {
    setSearch(event.target.value);
    // Clear the search result when users start typing a new keyword
  };

  const formSubmissionHandler = (event) => {
    event.preventDefault();
    // Clear the search text once we get the search result
    setSearch('');
  };

  return (
    <div className={classes.content}>
      <MainSearchForm
        onChangeCategory={categoryInputChangeHandler}
        onFormSubmission={formSubmissionHandler}
        searchCategoryString={search}
      />
    </div>
  );
};

MainSearch.propTypes = {
  children: PropTypes.any
};

export default MainSearch;
